<template>
  <div :id="id" :class="customClass"></div>
</template>
<script>
import * as d3 from "d3";
import $ from "jquery";

export default {
  props: ["id", "customClass"],
  data: function () {
    return {};
  },
  computed: {
    //树布局
    treeMap() {
      return d3
        .tree()
        .nodeSize([this.nodeSpaceBetween, 0])
        .separation((a, b) => {
          let result =
            a.parent === b.parent && !a.children && !b.children ? 1 : 2;
          if (result > 1) {
            let length = 0;
            length = a.children ? length + a.children.length : length;
            length = b.children ? length + b.children.length : length;
            result = length / 2 + 0.5;
          }
          return result;
        });
    },
  },
  methods: {
    //随机数，用于绑定id
    uuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    },
    init(
      data,
      {
        marginTop = 0,
        marginLeft = 0,
        marginBottom = 0,
        marginRight = 0,
        width = 800, // svg宽度
        height = 800, // svg高度
        multiple = 0.5, // 默认2，从主节点的边缘出发
        rootNodeLength = 10, // 根节点宽高
        branchNodeWidth = 50, // 分支宽度
        branchNodeHeight = 50, // 分支高度
        lineLength = 200, // 线长度
        nodeSpaceBetween = 100, // 节点间宽度
        direction = ["r", "l"],
        duration = 1000, //动画持续时长
        delay = 500, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        leftHalve = false
      } = {}
    ) {
      const innerHeight = height - marginTop - marginBottom;
      const innerWidth = width - marginLeft - marginRight;
      //中心点坐标
      this.centralPoint = [
        innerHeight / 2 + marginTop,
        innerWidth / 2 + marginLeft,
      ];
      // 创建svg标签
      const svg = d3.create("svg").attr("width", width).attr("height", height);
      $("#" + this.id).html(svg.node());

      // 创建g标签
      this.group = svg
        .append("g")
        .attr("transform", `translate(${marginTop}, ${marginLeft})`);

      // 定义Tree层级，并设置宽高
      this.treemap = d3.tree().size([innerHeight, innerWidth]);

      this.rootNodeLength = rootNodeLength;
      this.multiple = multiple;
      this.lineLength = lineLength;
      this.nodeSpaceBetween = nodeSpaceBetween;
      this.branchNodeWidth = branchNodeWidth;
      this.branchNodeHeight = branchNodeHeight;
      this.data = data;
      this.direction = direction;
      this.duration = duration;
      this.delay = delay;
      this.ease = ease;
      this.leftHalve = leftHalve
      //画出根节点
      this.drawRoot();
      //数据处理
      this.dealData();
    },
    //数据处理
    async dealData() {
      const _that = this;
      let root = {};
      _that.direction.forEach((item) => {
        root[item] = d3.hierarchy(_that.data[item]);
        root[item].x0 = _that.centralPoint[0]; //根节点x坐标
        root[item].y0 = _that.centralPoint[1]; //根节点Y坐标
        root[item].descendants().forEach((d) => {
          d._children = d.children; //添加_children属性，用于实现点击收缩及展开功能
          d.id = item + _that.uuid(); //绑定唯一标识ID
        });
      });
      _that.root = root;
      _that.direction.forEach((item) => {
        _that.update(root[item], item);
      });
    },
    drawRoot() {
      this.group
        .append("g")
        .attr("class", `${this.id}nodeMain`)
        .attr(
          "transform",
          `translate(${this.centralPoint[1]},${this.centralPoint[0]})`
        )
        .attr("opacity", "0")
        .append("foreignObject")
        .attr("width", this.rootNodeLength)
        .attr("height", this.rootNodeLength)
        .attr(
          "transform",
          `translate(-${this.rootNodeLength / 2}, -${this.rootNodeLength / 2})`
        )
        .append("xhtml:div")
        .attr("class", "main")
        .attr("style", `border-top: ${this.rootNodeLength / 2}px solid transparent; border-bottom: ${this.rootNodeLength / 2}px solid transparent; border-right: ${this.rootNodeLength}px solid #FFC100;`)
        .html('')
      // 根节点动画
      d3.select(`.${this.id}nodeMain`)
        .transition()
        .duration(this.duration)
        .attr("opacity", "1");
    },
    update(source, direction) {
      const _that = this;
      const dirRight = direction === "r" ? 1 : -1; //方向为右/左
      const className = `${direction}gNode`;
      const tree = _that.treeMap(_that.root[direction]);
      const nodes = tree.descendants(); //返回后代节点数组，第一个节点为自身，然后依次为所有子节点的拓扑排序
      nodes.forEach((d) => {
        //左右2部分，设置以中心点为圆点(默认左上角为远点)
        if (_that.leftHalve && dirRight < 0) {
            d.y = dirRight *
            (d.depth * _that.lineLength / 2 +
              _that.rootNodeLength * _that.multiple) +
          _that.centralPoint[1]
        } else {
           d.y =
            dirRight *
            (d.depth * _that.lineLength +
              _that.rootNodeLength * _that.multiple) +
          _that.centralPoint[1]
        }
        d.x = d.x + _that.centralPoint[0];
      });
      const links = nodes.slice(1);
      // 画节点
      //根据class名称获取左或者右的g节点，达到分块更新
      const node = this.group
        .selectAll(`g.${className}`)
        .data(nodes.slice(1), (d) => d.id);
      let nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", function (d) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .attr("opacity", "0");
      nodeEnter
        .append("foreignObject")
        // .attr("overflow", "inherit")
        .attr("width", _that.branchNodeWidth)
        .attr("height", _that.branchNodeHeight)
        .attr(
          "transform",
          `translate(${direction === "r" ? 0 : -1*_that.branchNodeWidth }, -${
            _that.branchNodeHeight / 2
          })`
        )
        .append("xhtml:div")
        .attr("class", "branch")
        .style("width", "100%")
        .style("height", "100%")
        .style("display", "flex")
        .style("align-items", "center")
        .style("justify-content", "center")
        .append("span")
        .attr(
          "class",
          (i, index) =>
            `node-branch node-branch-${direction} node-branch-${index}-${direction}`
        )
        .html((i) => i.data.name);
      // UPDATE
      let nodeUpdate = nodeEnter.merge(node);
      // 节点动画
      nodeUpdate
        .transition()
        .duration(_that.duration)
        .ease(d3[_that.ease + "Out"])
        .delay(_that.delay)
        .attr("transform", function (d) {
          return `translate(${d.y}, ${d.x})`;
        })
        .attr("opacity", "1");

      // 画线
      // Update the links 根据 className来实现分块更新
      const link = _that.group
        .selectAll(`path.${className}`)
        .data(links, (d) => d.id);
      const linkEnter = link
        .enter()
        .insert("path", "g")
        .attr("class", className)
        .attr("d", (d) => {
          const o = { x: source.x0, y: source.y0 };
          return _that.diagonal(o, o);
        })
        .attr("fill", "none")
        .attr("stroke-width", 2)
        .attr("stroke", "#FE9805")
        // .attr("stroke-dasharray", "5,5");
      // Transition links to their new position.
      link
        .merge(linkEnter)
        .transition()
        .duration(_that.duration)
        .ease(d3[_that.ease + "Out"])
        .delay(_that.delay)
        .attr("d", function (d) {
          return _that.centralPoint[1] > d.y ? _that.diagonal2(d, d.parent) : _that.diagonal(d, d.parent);
        });
    },
    //画连接线
    diagonal(s, d) {
      let path = `M ${d.y} ${d.x}, ${(s.y + d.y) / 2} ${d.x}, ${(s.y + d.y) / 2} ${s.x}, ${s.y} ${s.x}`
      return path;
    },
    diagonal2(s, d) {
      let path = `M ${d.y} ${d.x}, ${d.y} ${s.x}, ${s.y} ${s.x}`;
      return path;
    },
  },
};
</script>