<template>
  <div id="investmentMap">
    <div class="pages hidden" id="investmentMapPages">
      <div class="page_title"><span class="text hidden" id="investmentMapText"></span></div>
      <MainProduct ref="refMainProduct" v-if="isMainProductVisible" />
      <RenderTree ref="refRenderTree" v-if="isRenderTreeVisible" />
      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>
      <div ref="refWorkTreeHolder" v-if="isWorkTreeVisible">
        <CustomTree ref="refWorkTree" id="workTree" customClass="custom_tree_chart" />
      </div>
      <div ref="refReletionTreeHolder" v-if="isReletionTreeVisible">
        <Twoflanks ref="refReletionTree" id="reletionTree" customClass="custom_trees_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import MainProduct from './components/MainProduct.vue'
import RenderTree from './components/RenderTree.vue'
import tableChart from "@/components/tableChart/index.vue";
import CustomTree from "@/components/customTree/customTree.vue";
import Twoflanks from "@/components/customTree/twoflanks.vue";

export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      isMainProductVisible: false,
      isRenderTreeVisible: false,
      isQuoteTableVisible: false,
      isWorkTreeVisible: false,
      isReletionTreeVisible: false
    };
  },
  components: {
    MainProduct,
    RenderTree,
    tableChart,
    CustomTree,
    Twoflanks
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#investmentMapText").addClass("hidden");
        this.isMainProductVisible = false
        this.isRenderTreeVisible = false
        this.isQuoteTableVisible = false
        this.isWorkTreeVisible = false
        this.isReletionTreeVisible = false
        resolve();
      });
    },
    async show(commandData) {
      this.isMainProductVisible = false
      this.isRenderTreeVisible = false
      this.isQuoteTableVisible = false
      this.isWorkTreeVisible = false
      this.isReletionTreeVisible = false
      //设置标题
      const title = commandData.data.pageTitle;
      $("#investmentMapPages").removeClass("hidden");
      $("#investmentMapText").html(title);

      this.$nextTick(async () => {
        $("#investmentMapText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#investmentMapText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.type === '主要产品') {
          this.handleMainProduct(commandData)
        }
        if (commandData.type.indexOf('上游') !== -1 || commandData.type.indexOf('下游') !== -1) {
          this.handleRenderTree(commandData)
        }
        if (['十大投资股东', '高管图谱'].indexOf(commandData.type) !== -1) {
          this.handleQuoteTable(commandData)
        }
        if (['投资关系'].indexOf(commandData.type) !== -1) {
          this.handleReletionTree(commandData)
        }
        if (['任职情况'].indexOf(commandData.type) !== -1) {
          this.handleWorkTree(commandData)
        }
      });
    },
    handleMainProduct(commandData) {
      this.isMainProductVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderMainProduct(commandData)
      });
    },
    renderMainProduct(commandData) {
      this.$refs.refMainProduct.init(commandData)
    },
    handleRenderTree(commandData) {
      this.isRenderTreeVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderTree(commandData)
      });
    },
    renderTree(commandData) {
      this.$refs.refRenderTree.init(commandData)
    },
    handleQuoteTable(commandData) {
      this.isQuoteTableVisible = true
      this.$nextTick(() => {
        // 渲染折线图
        this.renderQuoteTable(commandData)
      });
    },
    renderQuoteTable(commandData) {
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col3Suffix: "%",
      });
    },
    handleReletionTree(commandData) {
      this.isReletionTreeVisible = true
      this.$nextTick(() => {
        // 渲染折线图
        this.renderReletionTree(commandData)
      });
    },
    renderReletionTree(commandData) {
      let fontSize = document.documentElement.style.fontSize
      let number = window.parseFloat(fontSize)
      let width = this.$refs.refReletionTreeHolder.clientWidth;
      this.$refs.refReletionTree.init(commandData.data.data, {
            width,
            height: width * 0.8,
            lineLength: 10 * number,
            leftHalve: true,
            marginRight: 5 * number,
            branchNodeWidth: 16.25 * number,
            branchNodeHeight: 3.75 * number,
        })
    },
    handleWorkTree(commandData) {
      this.isWorkTreeVisible = true
      this.$nextTick(() => {
        // 渲染折线图
        this.renderWorkTree(commandData)
      });
    },
    renderWorkTree(commandData) {
      let fontSize = document.documentElement.style.fontSize
      let number = window.parseFloat(fontSize)
      let width = this.$refs.refWorkTreeHolder.clientWidth;
      this.$refs.refWorkTree.CustomTreeChart(commandData.data.data, {
          width,
          height: width * 0.8,
          branchNodeWidth: 16.25 * number,
          branchNodeHeight: 3.75 * number,
          multiple: 1.1,
          lineWidth: 10 * number,
          nodeLength: 10 * number,
          marginBottom: 1.25 * number,
      })
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
