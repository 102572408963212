<template>
    <div class="main_product">
        <h3>{{ data.secname }}</h3>
        <p>{{ data.seccode }}</p>
        <ul v-if="Object.keys(data).length > 0">
            <li v-for="(item, index) in data.data.data.vMainProducts" :key="index">{{ item.sName }}</li>
        </ul>
        <div v-if="Object.keys(data).length > 0">
            <span>所属行业：</span>
            <span v-for="(item, index) in data.data.data.vBelongedIndustries" :key="index">{{ item.sName }}</span>
        </div>
        <div v-if="Object.keys(data).length > 0">
            <span>消费群体：</span>
            <span>{{ data.data.data.sConsumerGroup }}</span>
        </div>
        <div v-if="Object.keys(data).length > 0">
            <span>销售市场：</span>
            <span>{{ data.data.data.sSalesMarket }}</span>
        </div>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            data: {}
        }
    },
    methods: {
        init(data) {
            this.data = data
        }
    },
}
</script>
<style lang="less" scoped>
.main_product {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h3 {
        font-size: 50px;
    }
    > p {
        font-size: 30px;
        opacity: 0.7;
        margin: 8px 0 34px 0;
    }
    > ul {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        > li {
            background: #FF7700;
            padding: 12px 34px;
            border-radius: 4px;
            color: #fff;
            margin-right: 24px;
            margin-bottom: 24px;
            font-size: 30px;
        }
    }
    > div {
        align-self: flex-start;
        font-size: 30px;
        margin-top: 14px;
        > span:first-child {
            opacity: 0.7;
        }
    }
}
</style>