<template>
    <div class="render_tree">
        <template v-if="Object.keys(data).length > 0 && data.data.data.length > 0">
            <button>{{ data.compareStocks[0].secname }}</button>
            <div :class="['triangle', data.type.indexOf('上游') !== -1 ? 'up' : 'down']"></div>
            <ul>
                <li v-for="(item, index) in data.data.data.slice(0, 10)" :key="index">
                    <span>{{ item.sName }}</span>
                </li>
            </ul>
        </template>
        <template v-else>
            <div class="noData">暂无数据</div>
        </template>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            data: {}
        }
    },
    methods: {
        init(commandData) {
            this.data = commandData
        }
    }   
}
</script>
<style lang="less" scoped>
.render_tree {
    padding-left: 100px;
    position: relative;
    > button {
        font-size: 32px;
        background: #FF5A37;
        border-radius: 32px;
        padding: 10px 38px;
        border: 0;
        transform: translateX(-50%);
        color: #fff;
    }
    .up {
        transform: translateX(-50%) rotate(-180deg) !important;
    }
    .down {
        bottom: 0;
    }
    .triangle {
        width: 0;
        height: 0;
        border-top: 14px solid #FF5A37;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        transform: translateX(-50%);
        z-index: 1;
    }
    .noData {
        text-align: center;
        margin-left: -100px;
        margin-top: 48px;
        font-size: 24px;
    }
    > ul {
        display: flex;
        flex-direction: column;
        &::before {
            content: " ";
            width: 1px;
            height: 50px;
            background: #8F3823;
        }
        > li {
            display: flex;
            position: relative;
            height: 58px;
            &::before {
                content: " ";
                width: 58px;
                height: 1px;
                background: #8F3823;
            }
            &::after {
                position: absolute;
                content: " ";
                width: 1px;
                height: 58px;
                background: #8F3823;
                // transform: rotate(90deg);
                left: 0;
                top: 0;
            }
            > span {
                font-size: 32px;
                line-height: 32px;
                margin-top: -17px;
                margin-left: 4px;
            }
        }
    }
}
</style>